import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/ubuntu/flomesh.io-main/src/layouts/Fixed/DocMdx.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "核心"
    }}>{`核心`}</h2>
    <ul>
      <li parentName="ul">{`为任何类型输入的限速增加流量控制机制`}</li>
      <li parentName="ul">{`增加异步文件操作`}</li>
      <li parentName="ul">{`支持 OpenSSL 引擎`}</li>
    </ul>
    <h2 {...{
      "id": "过滤器"
    }}>{`过滤器`}</h2>
    <ul>
      <li parentName="ul">{`新增过滤器 `}<em parentName="li">{`tee`}</em></li>
      <li parentName="ul">{`新增过滤器 `}<em parentName="li">{`throttleConcurrency`}</em></li>
      <li parentName="ul">{`新增过滤器 `}<em parentName="li">{`demux`}</em>{`（原来的 `}<em parentName="li">{`demux`}</em>{`/`}<em parentName="li">{`mux`}</em>{` 过滤器被重新命名为 `}<em parentName="li">{`demuxQueue`}</em>{`/`}<em parentName="li">{`muxQueue`}</em>{`）`}</li>
      <li parentName="ul">{`过滤器 `}<em parentName="li">{`decodeMQTT`}</em>{` 支持 `}<em parentName="li">{`protocolLevel`}</em>{` 选项`}</li>
      <li parentName="ul">{`限速过滤器支持弱引用作为统计单元`}</li>
    </ul>
    <h2 {...{
      "id": "api"
    }}>{`API`}</h2>
    <ul>
      <li parentName="ul"><em parentName="li">{`algo.Cache`}</em>{` 增加 TTL 支持`}</li>
      <li parentName="ul">{`新方法 `}<em parentName="li">{`stats.Metric.valueOf()`}</em>{` 用于获取指标当前值`}</li>
      <li parentName="ul"><em parentName="li">{`Data.from()`}</em>{` 拒绝字符串以外的类型`}</li>
    </ul>
    <h2 {...{
      "id": "主要错误修复"
    }}>{`主要错误修复`}</h2>
    <ul>
      <li parentName="ul">{`修复 TLS 请求可能被卡在缓冲区的错误`}</li>
      <li parentName="ul">{`修复读取代码库中的目录时的死循环问题`}</li>
      <li parentName="ul">{`修复为部分 `}<em parentName="li">{`cypto`}</em>{` 的 APIs 提供 `}<em parentName="li">{`null`}</em>{` 值时的崩溃问题`}</li>
      <li parentName="ul">{`修复查询 `}<em parentName="li">{`stats.Histogram`}</em>{` 子指表示的崩溃问题`}</li>
      <li parentName="ul">{`修复 HTTP 304 响应导致无限等待的错误`}</li>
      <li parentName="ul">{`修复了 `}<em parentName="li">{`Data::shift_while()`}</em>{` 会导致 Dubbo 解码失败的错误`}</li>
      <li parentName="ul">{`修复了合并数据时 `}<em parentName="li">{`Data`}</em>{` 大小没有响应改变的问题`}</li>
    </ul>
    <h2 {...{
      "id": "构建"
    }}>{`构建`}</h2>
    <ul>
      <li parentName="ul">{`支持静态链接到 libc`}</li>
      <li parentName="ul">{`支持链接到外部 zlib`}</li>
    </ul>
    <h2 {...{
      "id": "文档"
    }}>{`文档`}</h2>
    <p>{`相比上个版本，文档的覆盖率大幅提升，包括英文、中文和日文。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      